@import '../../css/Veriable.scss';
:root {
  --color-white-100: hsl(206, 5%, 100%);
  --color-white-200: hsl(206, 5%, 90%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 60%);
  --color-white-500: hsl(206, 5%, 50%);

  --color-black-100: hsl(213, 23%, 8%);
  --color-black-200: hsl(214, 21%, 6%);
  --color-black-300: hsl(210, 21%, 6%);
  --color-black-400: hsl(216, 22%, 4%);
  --color-black-500: hsl(220, 18%, 3%);

  --color-pink-100: hsl(326, 85%, 90%);


  --secondcolor: #320073;
  --color_white: #ffffff;
  --color_white: #ffffff;
  --topplayer: #971313;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.fixed-top-custum{
   position:fixed;
   top:0px;
   left:0px;
   width:100%;
   transition:0.5s linear all;
}
.fixed-bottom{
   background: rgb(211 47 46);
   height: auto;
   bottom: 0px;
   position: fixed;
   width: 75%;
   padding: 7px 11px;
   color: #fff;
   display:none;
   h3{
      font-size: 19px;
   }
   
   .socialmedia{
      padding-bottom: 10px;
   .socialicon{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1c2127;
      margin: 0px 4px;
      .bi{
         color: #fff;
         display: flex;
    align-items: center;
      }
   } 
}}
.mobilelogo{
   display: none;
}
.menu-link {
   .bi{
      font-size: 15px;
   }

}
.tabsheader{
   .ant-tabs-tab{
      position:relative;
   &:before{
      content: "";
      background: #d32f2f;
      width: 1px;
      height: 48%;
      position: absolute;
      left: -4px;
      top: 27%;
   }
   :where(.css-dev-only-do-not-override-2q8sxy).ant-tabs .ant-tabs-ink-bar {
      position: absolute;
      background: #d32f2f;
      pointer-events: none;
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
   margin: 0 0 0 0px;
   padding: 0px 15px;
}
   }

}
a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
}
.logo{
   width:110px;
}
.loginandmenu button {
   width: 89px;
   height: 34px;
   flex-shrink: 0;
   border: none;
   box-shadow: none;
   background:$pinkcolor;
   color: #fff;
   border-radius: 5px;
   // margin-right: 15px;
 }  
 .mr-2{
   margin-right:10px;
 }
img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

// Elements
// .container {
//   max-width: 75rem;
//   height: auto;
//   margin: 0 auto;
//   padding: 0 1.25rem;
// }

.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: var(--color-pink-500);
}

// Header
.header {
//   position: fixed;
//   top: 0;
//   left: 0;
  width: 100%;
  height: auto;
  z-index: 100;
  margin: 0 auto;
  box-shadow: var(--shadow-medium);
  background-color: var(--color-white-100);
}

// Wrapper
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 2rem;
  width: 100%;
  height: 80px;
  margin: 0 auto;
}

// Navbar
.navbar {
  @media screen and (max-width: 992px) {
     display: inline-block;
     position: fixed;
     top: 0;
     left: -100%;
     width: 75%;
     height: 100%;
     z-index: 10;
     opacity: 0;
     overflow-y: auto;
     visibility: hidden;
     box-shadow: var(--shadow-medium);
     background-color: var(--color-white-100);
     transition: all 0.5s ease;

     &.active {
        left: 0rem;
        opacity: 1;
        visibility: visible;
        padding: 0px;
     }
  }
}

// Menu
.menu {
   margin-bottom: 0px;
  &-item {
     position: relative;
     display: inline-block;
     margin-left: 1.5rem;
     padding: 28px 0px;
  }

  &-link {
     display: flex;
     justify-content: center;
     align-items: center;
     column-gap: 0.25rem;
     font-family: inherit;
     font-size: 16px;
     font-weight: 500;
     line-height: inherit;
     cursor: pointer;
     text-transform: capitalize;
     color: var(--color-black-500);
     transition: all 0.3s ease-in-out;

     > i.bx {
        font-size: 1.35rem;
        line-height: 1.5;
        color: inherit;
     }

     &:hover {
        outline: none;
      //   color: var(--color-pink-500);
     }
  }

  // Responsive Media Query
  @media only screen and (min-width: 993px) {
     &-dropdown:hover {
        > .submenu {
           display: block;
           opacity: 1;
           visibility: visible;
           transform: translateY(0);
        }
     }
     
  }

  // Responsive Media Query
  @media only screen and (max-width: 992px) {
     width: 100%;
     height: auto;
     padding: 1rem 0;

     &-item {
        display: block;
        margin: 0 auto;
     }

     &-link {
      justify-content: space-between;
      padding: 5px 10px;
   }
 
  }
}

// Submenu
.submenu {
   position: absolute;
   top: 100%;
   left: -2rem;
   min-width: 250px;
   border-radius: 10px;
   height: auto;
   padding: 0px;
   opacity: 0;
   visibility: hidden;
   transform: translateY(1rem);
   border-radius: 0px;
   box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.5);
   background-color: var(--color-white-100);
   transition: all 0.3s ease-in-out;
   z-index: 8;
   border-top: 2px solid $pinkcolor;

  &-item {
     display: block;
   //   border-bottom: 1px solid $pinkcolor;
   }
   &-item:nth-last-child(-n +1){
      border-bottom: 0px solid $pinkcolor;
   }

  &-link {
   //   font-family: inherit;
   //   font-size: 16px;
   //   font-weight: 500;
   //   padding: 5px 10px;
   //   line-height: inherit;
   //   cursor: pointer;
   //   transition: all 0.35s ease;
   border-radius: 5px;
    color: #7b7b8a;
    padding: 9px 15px;
    font-size: 16px;
    font-weight: 500;
    display: block;
    position: relative;
    z-index: 1;
   // color: $black;
   // display: block;
   // font-size: 14px;
   // padding: 8px 15px;
   // border-radius: 5px;

     &:hover {
        outline: none;
        color: $pinkcolor;
     }
  }

  // Responsive Media Query
  @media only screen and (max-width: 992px) {
     position: relative;
     top: -0.5rem;
     left: 2.5rem;
     width: 100%;
     max-height: 0;
     padding: 0px;
     border: none;
     outline: none;
     opacity: 1;
     overflow: hidden;
     visibility: visible;
     transform: translateY(0px);
     box-shadow: none;
     background: transparent;
  }
 
}

// Burger
.burger {
  position: relative;
  display: none;
  cursor: pointer;
  user-select: none;
  width: 1.6rem;
  height: 1.15rem;
  opacity: 0;
  visibility: hidden;
  background: transparent;

  &-line {
     position: absolute;
     display: block;
     right: 0;
     width: 100%;
     height: 2.1px;
     opacity: 1;
     border: none;
     outline: none;
     border-radius: 1rem;
     background: var(--color-black-500);

     &:nth-child(1) {
        top: 0px;
     }
     &:nth-child(2) {
        top: 0.5rem;
        width: 70%;
     }
     &:nth-child(3) {
        top: 1rem;
     }
  }

  // Responsive Media Query
  @media only screen and (max-width: 992px) {
     display: block;
     opacity: 1;
     visibility: visible;
  }
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
  background-color: rgba(0, 0, 0, 0.65);

  // Responsive Media Query
  @media only screen and (max-width: 992px) {
     &.active {
        display: block;
        opacity: 1;
        visibility: visible;
     }
     &.active {
        display: block;
        opacity: 1;
        visibility: visible;
     }
  }
}

@media only screen and (max-width:991px){
   .fixed-bottom{
      display:block;
   }
   .submenu {
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      max-height: 0;
      padding: 0px;
      border: none;
      outline: none;
      opacity: 1;
      overflow: hidden;
      visibility: visible;
      transform: translateY(0px);
      box-shadow: none;
      background: #d32f2e7d;
  }
  .submenu-link {
   font-family: inherit;
   font-weight: 500;
   line-height: inherit;
   cursor: pointer;
   transition: all 0.35s ease;
   padding: 10px 30px;
   font-size: 14px;
   display: block;
   color: #fff;
   border-bottom: 1px solid rgba(255,255,255,.3);
}
.submenu-item {
   margin-top: 0rem;

}

   .navbar.active {
      background: $lightbluenavy;
  }
  .menu {
   padding: 0px;
   height: 77vh;
   overflow: scroll;
}
  .menu-link {
   justify-content: space-between;
   color: #fff;
   display: flex;
   font-size: 25px;
   font-weight: 400;
   border-left: none;
   .bi{
      font-size: 15px;
   }

}
   .loginandmenu {
      button{
        .menu-link.text-light {
          justify-content:center;
        }
      }
    }
   .mobilelogo{
      margin: 0px auto;
      display: flex;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid red;
      background: #fff;
      img{
         width: 150px;
         padding: 10px 0px;
      }
     }

}
.mr-5{
   margin-right:10px
}
.login{
   a{
      margin-left:5px;
   }
}
.sub_sub-menu{
   position: absolute;
   left: 102%;
   width: 100%;
   top: 0px;
   background: #fff;
   position: absolute;
    border-radius: 10px;
    height: auto;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
    border-radius: 0px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    background-color: var(--color-white-100);
    transition: all 0.3s ease-in-out;
    z-index: 8;
    border-top: 2px solid #DD127B;
}
.submenu .submenu-item:hover .sub_sub-menu{
   opacity: 1;
    visibility: visible;
}
.sub_sub-menu.show{
   opacity: 1;
   visibility: visible;
   position: relative;
   left:0%;
   width: 100%;
   top: 0px;
   background: #fff;
    border-radius: 10px;
    height: auto;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    background-color: var(--color-white-100);
    transition: all 0.3s ease-in-out;
    z-index: 8;
    border-top: 2px solid #DD127B;
}


  header nav {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
  }
  
  .logo {
	flex: 1;
	display: flex;
	align-items: center;
  }
  
  .logo a {
	text-decoration: none;
	font-size: 26px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 800;
  }
  
  .bartoggle,
  #menubrop {
	display: none;
  }
  
  .NavMenu {
	flex: 10;
	list-style: none;
	position: relative;
	display: flex;
	justify-content: end;
	z-index:999;
	margin-bottom: 0px;
  }
  
  .NavMenu li {
	display: inline-block;
    margin-left: 1.5rem;
    padding: 28px 0;
    position: relative;
  }
  
  .NavMenu li input {
	display: none;
  }
  
  .NavMenu li a {
	align-items: center;
    color: #060709;
    color: var(--color-black-500);
    column-gap: 0.25rem;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    line-height: inherit;
    text-transform: capitalize;
    transition: all .3s ease-in-out;
  }
  
  .NavMenu li a label {
	cursor: pointer;
	appearance: none;
	display: block;
	position: relative;
  }
  
//   .NavMenu li a label::after {
// 	content: "+";
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	font-size: 20px;
//   }
  
  .NavMenu>li>a label::after {
	right: -15px;
	top: -3px;
  }
  
  .NavMenu li ul {
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	min-width: 200px;
	border-bottom: 2px solid $pinkcolor;
	top: 100%;
	box-shadow: 0 3px 5px rgb(0 0 0/20%);
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transform: translateY(10px);
	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	-ms-transform: translateY(10px);
	-o-transform: translateY(10px);
	visibility: hidden;
	opacity: 0;
  }
  
  .NavMenu li ul li {
	position: relative;
	padding: 0px;
    margin-left: 0px;
  }
  
  .NavMenu li ul li a {
	color: #000;
	padding: 8px 10px;
	display: block;
	border-left: 2px solid transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
  }
  
  .NavMenu li ul li ul {
	position: absolute;
    left: 107%;
    top: 10px;
    white-space: nowrap;
    &:before{
      content: "";
    position: absolute;
    top: 4px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid $pinkcolor;
    left: -12px;
    opacity: 0.8;
    }
  }
  
  @media(min-width:992px) {
	.NavMenu li ul li a:hover {
	  border-left: 2px solid $pinkcolor;
	}
  
	.NavMenu li:hover>ul,
	.NavMenu li ul li:hover>ul {
		padding-left: 0px;
	  visibility: visible;
	  opacity: 1;
	  transform: translateY(0);
	  -webkit-transform: translateY(0);
	  -moz-transform: translateY(0);
	  -ms-transform: translateY(0);
	  -o-transform: translateY(0);
	}
  }
  
  @media(max-width:991.98px) {
   .NavMenu li ul {
      border-bottom: 0px solid #ffffff;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      box-shadow: none;
  }
	.NavMenu li {
		display: inline-block;  
		margin-left: 0px;
		padding: 6px 0;
		position: relative;
		text-align: left;
	}

   .logo {
      max-width: 90px;
      width: 100%;
      margin-left: 59px;
  }
  
	.bartoggle {
	  display: flex;
	  justify-content: center;
	  font-size: 30px;
	  align-items: center;
	  background-color: #fff;
	  padding: 0 10px;
	  cursor: pointer;
     position: absolute;
	}
  
	.NavMenu {
		width: 500px;
		flex: 12 1;
		position: fixed;
		flex-direction: column;
		background-color: #dfdfdf;
		left: 0;
		top: 79px;
		height: 100vh;
		z-index: 10;
		padding: 15px 0 50px 0;
		justify-content: start;
		overflow-y: scroll;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
		-moz-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		-o-transform: translateX(-100%);
	}
  
	.NavMenu li ul,
	.NavMenu li ul li ul {
	  position: initial;
	  left: 0;
	  visibility: visible;
	  opacity: 1;
	  top: 0;
	  display: none;
	}
  
	.NavMenu li a {
	  padding: 8px 15px;
	  border-bottom: 1px solid #fff;
	  justify-content: space-between;
	}
  
	.NavMenu li ul li ul {
	  background: #dfdfdf;
	  position: inherit;
	  margin-top: -10px;
	}
  
	.NavMenu li ul li ul li a {
	  font-size: 14px;
	  color: #000;
	  font-weight: 400;
	  text-transform: initial;
	  padding: 7px 15px 7px 30px;
	}
  
	.NavMenu li a label::after {
	  right: 10px;
	}
  
	.NavMenu li input:checked+ul,
	.NavMenu li ul li input:checked+ul {
	  display: flex;
	  flex-direction: column;
	  margin-bottom: 0px;
     padding-left: 0px;
	}
  
	input:checked+.NavMenu {
	  transform: translateX(0);
	  -webkit-transform: translateX(0);
	  -moz-transform: translateX(0);
	  -ms-transform: translateX(0);
	  -o-transform: translateX(0);
	  padding: 0px;
     
	}
  
  }
  
  @media(max-width:768px) {
	.NavMenu {
	  width: 100%;
	}
  }
	   